import React, { useState, useRef, useMemo, useCallback } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet'
import L from 'leaflet';
import { InfoBox } from './InfoBox';
import { Legend } from './Legend';
import { DataScopeSelector } from './DataScopeSelector';
import { getColor, round, getPerc } from './util';

import './index.css';

import illinois_zip_data from './data/illinois_shp_data_zip_w_linked_ext.min.json';

const dataScopes = [
    {
        name: "2016",
        key: "zip_2016_n_case",
    },
    {
        name: "2017",
        key: "zip_2017_n_case",
    },
    {
        name: "2018",
        key: "zip_2018_n_case",
    },
    {
        name: "2019",
        key: "zip_2019_n_case",
    },
    {
        name: "2020",
        key: "zip_2020_n_case",
    },
    {
        name: "2021",
        key: "zip_2021_n_case",
    },
    {
        name: "2022",
        key: "zip_2022_n_case",
    }
];

const dataWithHospitalInfoOrNot = [
    {
        name: "Yes",
        key: "_linked",
    },
    {
        name: "No",
        key: "",
    }
];

const colors = ["#30cb00", "#a4fba6", "#ffffff", "#ffbaba", "#ff0000", "#800000", "#000000"];
const colorScale = [-0.5, -0.05, 0.05, 0.5, 1, 2];

export default function ChoroplethMapZIP() {
    const [selectedArea, setSelectedArea] = useState(null);
    const [hoveredArea, setHoveredArea] = useState(null);

    const [startingYear, setStartingYear] = useState("2016");
    const [endingYear, setEndingYear] = useState("2016");
    const [dataWithHospitalInfoOnly, setDataWithHospitalInfoOnly] = useState("Yes");

    const geoMap = useRef(null);

    const handleStartingYearChange = (event) => {
        setStartingYear(dataScopes.find(element => element.key === event.target.value)["name"]);
    }

    const handleEndingYearChange = (event) => {
        setEndingYear(dataScopes.find(element => element.key === event.target.value)["name"]);
    }

    const handleHospitalInfoScopeChange = (event) => {
        setDataWithHospitalInfoOnly(dataWithHospitalInfoOrNot.find(element => element.key === event.target.value)["name"]);
    }

    const highlightFeature = (e) => {
        let layer = e.target;
        layer.setStyle({
            color: '#444',
            weight: 2
        });
        layer.bringToFront();
        setHoveredArea(layer.feature.properties);
    }

    const resetHighlight = (e) => {
        e.target.setStyle({
            color: '#888',
            weight: 1
        });
        setHoveredArea(null);
    }

    const onEachFeature = useCallback((feature, layer) => {
        let curr_zip = feature.properties["ZCTA5CE10"];
        let crashChangePerc = 0;
        let startingYearNumber = 0;
        let endingYearNumber = 0;

        let starting_n_drivers = 0;
        let starting_n_passengers = 0;
        let starting_n_pedestrains = 0;
        let starting_n_pedalcyclists = 0;
        let starting_average_iss = 0;
        let starting_drug_involvement_perc = 0.0;

        let ending_n_drivers = 0;
        let ending_n_passengers = 0;
        let ending_n_pedestrains = 0;
        let ending_n_pedalcyclists = 0;
        let ending_average_iss = 0;
        let ending_drug_involvement_perc = 0.0;

        
        if (dataWithHospitalInfoOnly === "Yes") {
            startingYearNumber = feature.properties["zip_" + startingYear + "_n_case_linked"];
            endingYearNumber = feature.properties["zip_" + endingYear + "_n_case_linked"];
            starting_n_drivers = feature.properties["zip_" + startingYear + "_n_drivers_linked"];
            starting_n_passengers = feature.properties["zip_" + startingYear + "_n_passengers_linked"];
            starting_n_pedestrains = feature.properties["zip_" + startingYear + "_n_pedestrians_linked"];
            starting_n_pedalcyclists = feature.properties["zip_" + startingYear + "_n_pedalcyclists_linked"];
            starting_average_iss = feature.properties["zip_" + startingYear + "_avg_iss_linked"].toFixed(2);
            starting_drug_involvement_perc = getPerc(feature.properties["zip_" + startingYear + "_n_drug_involved_linked"], feature.properties["zip_" + startingYear + "_n_drug_not_involved_linked"]).toFixed(2);
            ending_n_drivers = feature.properties["zip_" + endingYear + "_n_drivers_linked"];
            ending_n_passengers = feature.properties["zip_" + endingYear + "_n_passengers_linked"];
            ending_n_pedestrains = feature.properties["zip_" + endingYear + "_n_pedestrians_linked"];
            ending_n_pedalcyclists = feature.properties["zip_" + endingYear + "_n_pedalcyclists_linked"];
            ending_average_iss = feature.properties["zip_" + endingYear + "_avg_iss_linked"].toFixed(2);
            ending_drug_involvement_perc = getPerc(feature.properties["zip_" + endingYear + "_n_drug_involved_linked"], feature.properties["zip_" + endingYear + "_n_drug_not_involved_linked"]).toFixed(2);
        } else {
            startingYearNumber = feature.properties["zip_" + startingYear + "_n_case"];
            endingYearNumber = feature.properties["zip_" + endingYear + "_n_case"];
            starting_n_drivers = feature.properties["zip_" + startingYear + "_n_drivers"];
            starting_n_passengers = feature.properties["zip_" + startingYear + "_n_passengers"];
            starting_n_pedestrains = feature.properties["zip_" + startingYear + "_n_pedestrians"];
            starting_n_pedalcyclists = feature.properties["zip_" + startingYear + "_n_pedalcyclists"];
            ending_n_drivers = feature.properties["zip_" + endingYear + "_n_drivers"];
            ending_n_passengers = feature.properties["zip_" + endingYear + "_n_passengers"];
            ending_n_pedestrains = feature.properties["zip_" + endingYear + "_n_pedestrians"];
            ending_n_pedalcyclists = feature.properties["zip_" + endingYear + "_n_pedalcyclists"];
        }

        if (startingYear !== endingYear) {
            if (startingYearNumber === 0) {
                startingYearNumber += 1;
            }
            if (endingYearNumber - startingYearNumber !== 0) {
                crashChangePerc = round((endingYearNumber - startingYearNumber) / (startingYearNumber) * 100, 2);
            }
        }

        if (geoMap.current) {
            const current = geoMap.current.getLayers().find(e => e.feature.properties.ZCTA5CE10 === feature.properties.ZCTA5CE10);

            if (startingYear === endingYear) {
                current.setTooltipContent(`<div><span>Please select two different years...</span></div>`);
            } else {
                if (dataWithHospitalInfoOnly === "Yes") {
                    current.setTooltipContent(
                        `<div><span>
                        ZIP: ${curr_zip}</br>
                        </br>
                        Relative change of crashes: ${crashChangePerc}%</br>
                        </br>
                        Starting Year: ${startingYear}</br>
                        Crashes: ${startingYearNumber}</br>
                        Drivers: ${starting_n_drivers}</br>
                        Passengers: ${starting_n_passengers}</br>
                        Pedestrains: ${starting_n_pedestrains}</br>
                        Pedalcyclists: ${starting_n_pedalcyclists}</br>
                        Avg. ISS: ${starting_average_iss}</br>
                        Drug involved (person): ${starting_drug_involvement_perc}%</br>
                        </br>
                        Ending Year: ${endingYear}</br>
                        Crashes: ${endingYearNumber}</br>
                        Drivers: ${ending_n_drivers}</br>
                        Passengers: ${ending_n_passengers}</br>
                        Pedestrains: ${ending_n_pedestrains}</br>
                        Pedalcyclists: ${ending_n_pedalcyclists}</br>
                        Avg. ISS: ${ending_average_iss}</br>
                        Drug involved (person): ${ending_drug_involvement_perc}%</br>
                        </span></div>`
                    );
                } else {
                    current.setTooltipContent(
                        `<div><span>
                        ZIP: ${curr_zip}</br>
                        </br>
                        Relative change of crashes: ${crashChangePerc}%</br>
                        </br>
                        Starting Year: ${startingYear}</br>
                        Crashes: ${startingYearNumber}</br>
                        Drivers: ${starting_n_drivers}</br>
                        Passengers: ${starting_n_passengers}</br>
                        Pedestrains: ${starting_n_pedestrains}</br>
                        Pedalcyclists: ${starting_n_pedalcyclists}</br>
                        </br>
                        Ending Year: ${endingYear}</br>
                        Crashes: ${endingYearNumber}</br>
                        Drivers: ${ending_n_drivers}</br>
                        Passengers: ${ending_n_passengers}</br>
                        Pedestrains: ${ending_n_pedestrains}</br>
                        Pedalcyclists: ${ending_n_pedalcyclists}</br>
                        </span></div>`
                    );
                }
            }
        } else {
            layer.bindTooltip(`<div><span>Please select the starting and ending years...</span></div>`, { sticky: true });

            layer.on({
                mouseover: highlightFeature,
                mouseout: resetHighlight,
                click: () => setSelectedArea(feature.properties)
            });
        }
    }, [startingYear, endingYear, dataWithHospitalInfoOnly])

    const style = useCallback((feature) => {
        let crashChangePerc = 0;
        let startingYearNumber = 0;
        let endingYearNumber = 0;
        if (dataWithHospitalInfoOnly === "Yes") {
            startingYearNumber = feature.properties["zip_" + startingYear + "_n_case_linked"];
            endingYearNumber = feature.properties["zip_" + endingYear + "_n_case_linked"];
        } else {
            startingYearNumber = feature.properties["zip_" + startingYear + "_n_case"];
            endingYearNumber = feature.properties["zip_" + endingYear + "_n_case"];
        }

        if (startingYear !== endingYear) {
            if (startingYearNumber === 0) {
                startingYearNumber += 1;
            }
            if (endingYearNumber - startingYearNumber !== 0) {
                crashChangePerc = (endingYearNumber - startingYearNumber) / startingYearNumber;
            }
        }

        let mapStyle = {
            fillColor: getColor(crashChangePerc, colors, colorScale),
            weight: 1,
            opacity: 1,
            color: '#888',
            dashArray: '3',
            fillOpacity: 0.7
        };

        return mapStyle;
    }, [startingYear, endingYear, dataWithHospitalInfoOnly]);

    const geoJsonComponent = useMemo(
        () => <GeoJSON data={illinois_zip_data} style={style} onEachFeature={onEachFeature} ref={geoMap} />,
        [style, onEachFeature]
    );

    return (
        <div className='mapContainer' >
            <p>Starting Year: <DataScopeSelector options={dataScopes} value={startingYear} changeHandler={handleStartingYearChange} />&nbsp;&nbsp;&nbsp;Ending Year: <DataScopeSelector options={dataScopes} value={endingYear} changeHandler={handleEndingYearChange} /></p>
            <p>Data with Hospital Data Only: <DataScopeSelector options={dataWithHospitalInfoOrNot} value={dataWithHospitalInfoOnly} changeHandler={handleHospitalInfoScopeChange} /></p>
            <MapContainer center={[40, -89]}
                zoomControl={false}
                zoom={8}
                zoomSnap={0.5}
                zoomDelta={0.5}
                wheelPxPerZoomLevel={120}
                maxBoundsViscosity={0.5}
                maxBounds={L.latLngBounds(new L.LatLng(85, -210), new L.LatLng(-85, 210))}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {geoJsonComponent}
                <InfoBox data={selectedArea} dataType="ZIP"/>
                <Legend scale={colorScale} startingYear={startingYear} endingYear={endingYear} dataWithHospitalInfoOnly={dataWithHospitalInfoOnly} colors={colors} hoveredArea={hoveredArea} dataType="zip" />
            </MapContainer>
            <p>Footnotes:</p>
            <p>1. Relative change of crashes = (ending year - starting year) / starting year * 100%</p>
            <p>2. ISS stands for Injury Severity Score</p>
        </div>
    );
}