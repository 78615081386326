function getColor(val, colors, scale) {
    let colorsToUse = colors;

    if (!val && val !== 0) {
        return colorsToUse[0];
    }

    for (let i = 0; i < scale.length; i++) {
        if (val < scale[i]) {
            return colorsToUse[i];
        }
    }

    return colorsToUse[colorsToUse.length - 1];
}

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return (Math.round(value * multiplier) / multiplier).toString();
}

function getPerc(a, b) {
    if (a === 0) {
        return 0;
    }
    return a / (a + b) * 100;
}

export { getColor, round, getPerc }