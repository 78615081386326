import Control from "react-leaflet-custom-control";
import { getColor, round } from './util';
import './legend.css';

export function Legend({ scale, startingYear, endingYear, dataWithHospitalInfoOnly, colors, hoveredArea, dataType }) {
    let legend = [];

    for (let i = -1; i < scale.length; i++) {
        let from;
        let to;

        if (i === -1) {
            from = null;
            to = scale[0];
        } else {
            from = scale[i];
            to = scale[i + 1];
        }

        let hoveredValue = null;

        if (hoveredArea) {
            let crashChangePerc = 0;
            let startingYearNumber = 0;
            let endingYearNumber = 0;
            if (dataWithHospitalInfoOnly === "Yes") {
                startingYearNumber = hoveredArea[dataType + "_" + startingYear + "_n_case_linked"];
                endingYearNumber = hoveredArea[dataType + "_" + endingYear + "_n_case_linked"];
            } else {
                startingYearNumber = hoveredArea[dataType + "_" + startingYear + "_n_case"];
                endingYearNumber = hoveredArea[dataType + "_" + endingYear + "_n_case"];
            }

            if (startingYear !== endingYear) {
                if (startingYearNumber === 0) {
                    startingYearNumber += 1;
                }
                if (endingYearNumber - startingYearNumber !== 0) {
                    crashChangePerc = round((endingYearNumber - startingYearNumber) / (startingYearNumber), 2);
                }
            }
            
            hoveredValue = crashChangePerc;
        }

        let left = from ? from * 100 : -100;
        let right = to * 100;

        // We assign -1.0 to "from" if it doesn't have a value, because it happens only when it's the at the leftmost side of the spectrum
        if (!from) {
            from = -1.0
        }

        if (hoveredValue !== null && (hoveredValue >= from && (!to || hoveredValue < to))) {
            legend.push(<tr className="legendRowHighlighted" key={from}>
                <td><i style={{ background: getColor(from, colors, scale) }}></i></td>
                <td className='leftCol'>{left}</td><td>&nbsp;to&nbsp;</td><td className='rightCol'>{right ? right : '∞'}</td>
            </tr>);
        } else {
            legend.push(<tr className="legendRow" key={from}>
                <td><i style={{ background: getColor(from, colors, scale) }}></i></td>
                <td className='leftCol'>{left}</td><td>&nbsp;to&nbsp;</td><td className='rightCol'>{right ? right : '∞'}</td>
            </tr>);
        }
    }

    return (
        <Control position="bottomright">
            <div className="legend-box legend">
                Relative change %
                <table>
                    <tbody>
                        {legend}
                    </tbody>
                </table>
            </div>
        </Control>
    )
}