import Control from "react-leaflet-custom-control";

import { round } from './util';

import './infobox.css';

function numberWithCommas(x) {
    if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return '';
    }
}



export function InfoBox({ data, dataType }) {
    let infoBox;
    if (data != null) {
        infoBox = <div className="info"><h4>{dataType} Code: {dataType === 'ZIP' ? data.ZCTA5CE10 : data.GEOID}</h4>
            <br></br>
            <b>Population: </b> {numberWithCommas(data.population)}
            <br></br>
            <b>Poverty: </b> {round(data.poverty_rate, 1)}%
            <br></br>
            <b>White alone (NH): </b> {round(data.perc_nh_white, 1)}%
            <br></br>
            <b>Black or African American alone (NH): </b> {round(data.perc_nh_black, 1)}%
            <br></br>
            <b>Native American or Alaska Native alone (NH): </b> {round(data.perc_nh_indian, 1)}%
            <br></br>
            <b>Asian alone (NH): </b> {round(data.perc_nh_asian, 1)}%
            <br></br>
            <b>Pacific Islander alone (NH): </b> {round(data.perc_nh_pi, 1)}%
            <br></br>
            <b>Some Other Race alone (NH): </b> {round(data.perc_nh_other, 1)}%
            <br></br>
            <b>Mixed Race/Multi-Racial alone (NH): </b> {round(data.perc_nh_mixed, 1)}%
            <br></br>
            <b>Hispanic or Latino (any race): </b> {round(data.perc_hisp, 1)}%</div>;
    } else {
        infoBox = <h4><i>Select a {dataType} area</i></h4>;
    }

    return (
        <Control position='topright'>
            {infoBox}
        </Control>
    )
}