import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChoroplethMapZIP from "./ChoroplethMapZIP";
import ChoroplethMapBG from "./ChoroplethMapBG";
import Layout from "./layout";
import Home from "./home";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="zip-map" element={<ChoroplethMapZIP />} />
                    <Route path="block-group-map" element={<ChoroplethMapBG />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}